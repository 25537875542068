import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/administracion.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'

import FooterContact from './../components/FooterContact'

const Admin = props => (
  <Layout
    title="Sevicio de Administración"
    description="Te ayudamos a optimizar tus ganancias y tiempo con nuestros servicios complementarios."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.administracion.title" />
      </TitleSection>
      <div
        style={{ textAlign: 'justify', maxWidth: '700px', margin: '0 auto' }}
      >
        <div>
          <p>
            <FormattedMessage id="solutions.administracion.content.p1" />
          </p>
          <p>
            <FormattedHTMLMessage id="solutions.administracion.content.p2" />
          </p>
          <ul>
            {_.map(_.times(11, String), (value, key) => (
              <li key={key}>
                <FormattedMessage
                  id={`solutions.administracion.content.list.item${value}`}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
    <FooterContact />
  </Layout>
)

export default Admin
